import React, { ComponentType } from 'react'
import { InputProps } from 'src/components/ui/kit/inputs/InputComponent'
import { basicInput, withBorder } from 'src/components/ui/kit/inputs/style'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

export type FileValue = {
    value: string
    // eslint-disable-next-line no-undef
    file: File | null
}

const FileInput: ComponentType<
    InputProps<FileValue> & {
        id?: string
        placeholder?: string
    }
> = ({ onChange, onBlur, value, id, placeholder }) => (
    <input
        value={value.value || ''}
        id={id}
        type="file"
        onBlur={onBlur}
        onChange={(e) =>
            onChange({
                value: e.target.value,
                file: e.target.files && e.target.files[0]
            })
        }
        css={(th: Theme) => css`
            ${basicInput(th)}
            ${withBorder(th)}
        `}
        placeholder={placeholder}
    />
)

export default FileInput
