import { graphql } from 'gatsby'
import React, { useRef, useState } from 'react'
import CareerPosition, {
    CareerPositionWrapper
} from 'src/components/career/CareerPosition'
import Container from 'src/components/Container'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { SrcImagesFragment } from 'src/page/fragments'
import { PageContext } from 'src/page/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import CareerForm from 'src/components/career/CareerForm'
import Header from 'src/components/Header'
import { useCareerPositions } from 'src/api_netlify/useCareerPositions'
import Spinner from 'src/components/Spinner'
import { ImageGallery } from 'src/components/Slider'

type CareerPageProps = {
    data: {
        careerImg: SrcImagesFragment
        galleryImages: {
            nodes: SrcImagesFragment[]
        }
    }
    pageContext: PageContext
    // eslint-disable-next-line no-undef
    location: Location
}

const CareerPage = ({
    data: pageData,
    pageContext,
    location
}: CareerPageProps) => {
    const { msgs, lang } = pageContext
    const { data, isLoading } = useCareerPositions()
    const formWrapperRef = useRef()
    const [pickedOption, setPickedOption] = useState('')
    const setPickedPosition = (pos: string) => {
        if (formWrapperRef.current) {
            // @ts-ignore
            formWrapperRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        setPickedOption(pos)
    }
    const otherPosition = t(msgs.careerPage.otherPosition)
    const positionOptions = data ? data.map((p) => p.title) : []
    positionOptions.push(otherPosition)
    const { galleryImages } = pageData

    const displayedGalleryImages = galleryImages.nodes.map((gi) => ({
        src: gi,
        alt: 'Garwan'
    }))
    return (
        <Layout pageCtx={pageContext}>
            <PageHeader subtitle={t(msgs.careerPage.subtitle)}>
                {t(msgs.careerPage.title)}
            </PageHeader>
            <Container>
                <div
                    css={(th: Theme) => css`
                        display: grid;
                        align-items: start;
                        grid-template-columns: 58% 38%; // 58 + 38 + 4 = 100
                        @media (max-width: ${th.mediaQuery.md}) {
                            grid-template-columns: none;
                        }
                        grid-gap: ${th.spacing.md} 4%;
                    `}
                >
                    <div>
                        <div
                            css={(th: Theme) => css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin-bottom: ${th.spacing.md};
                            `}
                        >
                            <Spinner pending={isLoading} />
                        </div>
                        {data &&
                            data.map((position) => (
                                <CareerPosition
                                    {...position}
                                    lang={lang}
                                    locationObj={location}
                                    key={position.id}
                                    onClick={() =>
                                        setPickedPosition(position.title)
                                    }
                                    msgs={msgs}
                                />
                            ))}
                        <CareerPositionWrapper
                            msgs={msgs}
                            onClick={() => setPickedPosition(otherPosition)}
                            title={t(msgs.careerPage.anyPositionTitle)}
                        >
                            {t(msgs.careerPage.anyPositionDescription)}
                        </CareerPositionWrapper>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        `}
                        // @ts-ignore
                        ref={formWrapperRef}
                    >
                        <div
                            css={(th: Theme) => css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                                > *:first-of-type {
                                    margin-bottom: ${th.spacing.md};
                                }
                            `}
                        >
                            <Header size={2} uppercase>
                                {t(msgs.careerPage.formTitle)}
                            </Header>
                            <span
                                css={(th: Theme) => css`
                                    font-size: ${th.fontSize.md};
                                    font-weight: ${th.fontWeight.thin};
                                    margin-bottom: ${th.spacing.lg};
                                `}
                            >
                                {t(msgs.careerPage.formSubtitle)}
                            </span>
                        </div>
                        <CareerForm
                            msgs={msgs}
                            pickedOption={pickedOption}
                            positionOptions={positionOptions}
                        />
                    </div>
                </div>
                <Header size={2}>{t(msgs.careerPage.ourPlace)}</Header>
                <ImageGallery images={displayedGalleryImages} />
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
    query CareerPageQuery {
        careerImg: file(
            sourceInstanceName: { eq: "src-images" }
            name: { eq: "career" }
        ) {
            ...SrcImages
        }
        galleryImages: allFile(
            sort: { fields: name }
            filter: {
                sourceInstanceName: { eq: "src-images" }
                relativeDirectory: { eq: "gallery" }
            }
        ) {
            nodes {
                ...SrcImages_FullWidth
            }
        }
    }
`

export default CareerPage
