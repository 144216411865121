import React from 'react'
import FormField from 'src/components/ui/form/FormField'
import { dropdownSelectWithOptions } from 'src/components/ui/kit/inputs/DropdownSelect'
import TextInput from 'src/components/ui/kit/inputs/TextInput'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import GdprConsent from 'src/components/ui/kit/GdprConsent'
import { valueLabelOptions } from 'src/form/utils'
import { Validator } from 'src/validation/Validator'
import SubmitPanel from 'src/components/ui/form/SubmitPanel'
import { eq, notEmpty } from '@ps-aux/revalid'
import { email } from 'src/validation/rules'
import EmailForm from 'src/components/ui/form/EmailForm'
import FileInput from 'src/components/ui/kit/inputs/FileInput'

const careerFormName = 'career-form'

const validate = Validator({
    firstName: [notEmpty()],
    lastName: [notEmpty()],
    email: [email(), notEmpty()],
    position: [notEmpty()],
    gdprConsent: [eq(true)]
}).validate

const CareerForm = ({
    msgs,
    positionOptions,
    pickedOption
}: {
    msgs: Msgs
    positionOptions: string[]
    pickedOption: string
}) => {
    const servicesOptions = Object.values(msgs.careerPage.jobTypeOptions)
    return (
        <EmailForm
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                position: pickedOption,
                cv: '',
                phoneNumber: '',
                jobType: '',
                jobStart: '',
                message: '',
                gdprConsent: false
            }}
            validate={validate}
            formName={careerFormName}
            css={(th: Theme) => css`
                display: flex;
                flex-direction: column;
                > *:not(:last-of-type) {
                    margin-bottom: ${th.spacing.lg};
                }
            `}
        >
            <div
                css={(th: Theme) => css`
                    display: flex;
                    justify-content: space-between;
                    > * {
                        flex-basis: 48%;
                        @media (max-width: ${th.mediaQuery.md}) {
                            flex-basis: 100%;
                            &:not(:last-of-type) {
                                margin-bottom: ${th.spacing.lg};
                            }
                        }
                    }
                    @media (max-width: ${th.mediaQuery.md}) {
                        flex-direction: column;
                        align-items: stretch;
                    }
                `}
            >
                <FormField
                    comp={TextInput}
                    name="firstName"
                    label={t(msgs.generalForm.firstName)}
                    markRequired={true}
                />
                <FormField
                    comp={TextInput}
                    name="lastName"
                    label={t(msgs.generalForm.lastName)}
                    markRequired={true}
                />
            </div>

            <FormField
                comp={TextInput}
                name="email"
                label={t(msgs.contact.email)}
                markRequired={true}
            />
            <FormField
                comp={dropdownSelectWithOptions(
                    valueLabelOptions(positionOptions)
                )}
                name="position"
                label={t(msgs.careerPage.position)}
                placeholder={t(msgs.careerPage.positionPlaceholder)}
                markRequired={true}
            />
            <FormField
                comp={FileInput}
                name="cv"
                label={t(msgs.careerPage.cv)}
                markRequired={true}
            />
            <FormField
                comp={TextInput}
                name="phoneNumber"
                label={t(msgs.contact.phone)}
            />
            <FormField
                comp={dropdownSelectWithOptions(
                    valueLabelOptions(servicesOptions)
                )}
                name="jobType"
                label={t(msgs.career.jobType)}
                placeholder={t(msgs.careerPage.jobTypePlaceholder)}
            />
            <FormField
                comp={TextInput}
                name="jobStart"
                label={t(msgs.career.jobStart)}
            />
            <FormField
                comp={TextInput}
                name="message"
                label={t(msgs.generalForm.message)}
                textArea={true}
                rows={5}
            />
            <FormField
                comp={(props) => <GdprConsent {...props} />}
                name="gdprConsent"
                type="checkbox"
            />
            <SubmitPanel msgs={msgs}>{t(msgs.generalForm.submit)}</SubmitPanel>
        </EmailForm>
    )
}

export default CareerForm
