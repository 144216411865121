import React, {
    ComponentType,
    ReactElement,
    useEffect,
    useRef,
    useState
} from 'react'
import CareerPositionAccordion from 'src/components/career/CareerPositionAccordion'
import Header from 'src/components/Header'
import Button from 'src/components/ui/kit/Button'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import Icon, { iconColors } from 'src/icons'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { CareerPositionInfo } from 'src/model/careerPositionType'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share'
import FormFieldUi from 'src/components/ui/form/FormFieldUi'
import TextInput from 'src/components/ui/kit/inputs/TextInput'
import { useNavigationData } from 'src/navigation/Navigation'
import { Lang } from 'src/i18n/lang/types'
import { pageNames } from 'src/pageNames'

// Offset for header
export const CareerPositionWrapper: ComponentType<{
    id?: string
    title: string
    shareComp?: ReactElement
    msgs: Msgs
    onClick: () => void
}> = ({ id, title, children, shareComp, onClick, msgs }) => {
    return (
        <div id={id}>
            <div>
                <div
                    css={(th: Theme) => css`
                        margin: 0 0 ${th.spacing.md};
                        padding: 0 0 ${th.spacing.sm};
                        border-bottom: 2px solid ${th.colors.primary};
                    `}
                >
                    <Header size={2}>{title}</Header>
                </div>
                {children}
                <div
                    css={(th: Theme) => css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: ${th.spacing.md};
                    `}
                >
                    <Button type="primary" onClick={onClick}>
                        {t(msgs.career.interested)}
                    </Button>
                    {shareComp}
                </div>
            </div>
        </div>
    )
}

const ShareIcon = ({
    onClick,
    children,
    hoverColor
}: {
    onClick?: () => void
    hoverColor?: string
    children: any
}) => (
    <span
        css={(th: Theme) => css`
            display: inline-block;
            border: none;
            padding: 0 ${th.spacing.sm};
            font-size: ${th.fontSize.md};
            outline: 0;
            text-align: center;
            color: ${th.colors.secondary};
            cursor: pointer;
            &:hover {
                color: ${hoverColor || th.colors.secondary};
            }
        `}
        onClick={onClick}
    >
        {children}
    </span>
)

const CareerPosition = ({
    msgs,
    lang,
    locationObj,
    onClick,
    ...position
}: CareerPositionInfo & {
    lang: Lang
    // eslint-disable-next-line no-undef
    locationObj: Location
    msgs: Msgs
    onClick: () => void
}) => {
    const { pages } = useNavigationData(lang)
    const careerPath =
        pages.filter((i) => i.name === pageNames.career)[0] || '/career/'
    const id = `${position.title.toLowerCase().split(' ').join('-')}-${
        position.id
    }`
    const shareUrl = `${locationObj.origin}${careerPath.path}#${id}`
    const [showShareLink, setShowShareLink] = useState(false)
    const ref = useRef()
    useEffect(() => {
        if (locationObj.hash.replace('#', '') === id && ref && ref.current) {
            // @ts-ignore
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])
    return (
        <div
            css={(th: Theme) => css`
                margin-bottom: ${th.spacing.xl};
                position: relative;
            `}
        >
            {/* Anchor for scrolling, offset required */}
            <div
                css={(th: Theme) => css`
                    position: absolute;
                    top: calc(-${th.headerHeight} - ${th.spacing.md});
                `}
                // @ts-ignore
                ref={ref}
            />
            <CareerPositionWrapper
                id={id}
                title={position.title}
                msgs={msgs}
                onClick={onClick}
                shareComp={
                    <div>
                        <FacebookShareButton url={shareUrl}>
                            <ShareIcon hoverColor={iconColors.facebook}>
                                <Icon.Facebook />
                            </ShareIcon>
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl}>
                            <ShareIcon hoverColor={iconColors.twitter}>
                                <Icon.Twitter />
                            </ShareIcon>
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl}>
                            <ShareIcon hoverColor={iconColors.linkedin}>
                                <Icon.Linkedin />
                            </ShareIcon>
                        </LinkedinShareButton>
                        <ShareIcon
                            onClick={() =>
                                setShowShareLink((prevState) => !prevState)
                            }
                        >
                            <Icon.Share />
                        </ShareIcon>
                    </div>
                }
            >
                <p dangerouslySetInnerHTML={{ __html: position.description }} />
                <CareerPositionAccordion position={position} msgs={msgs} />
            </CareerPositionWrapper>
            {showShareLink && (
                <div
                    css={(th: Theme) => css`
                        margin-top: ${th.spacing.md};
                    `}
                >
                    <FormFieldUi
                        htmlForId={`share-link-${position.id}`}
                        label={t(msgs.careerPage.shareLink)}
                    >
                        {() => (
                            <TextInput
                                value={shareUrl}
                                onChange={() => null}
                                onFocus={(e) => {
                                    e.target.select()
                                }}
                            />
                        )}
                    </FormFieldUi>
                </div>
            )}
        </div>
    )
}

export default CareerPosition
