import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import Icon, { IconType } from 'src/icons'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { CareerPositionInfo } from 'src/model/careerPositionType'

const CareerPositionAccordionItem = ({
    heading,
    children
}: {
    heading: string
    children: any
}) => (
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton
                css={(th: Theme) => css`
                    background-color: ${th.colors.lightGray};
                    padding: ${th.spacing.md} 0 ${th.spacing.md}
                        ${th.spacing.md};
                    margin-bottom: ${th.spacing.sm};
                    color: ${th.colors.secondary};
                    font-size: ${th.fontSize.sm};
                    font-weight: ${th.fontWeight.bold};
                    cursor: pointer;
                `}
            >
                <AccordionItemState>
                    {({ expanded }) => (
                        <span
                            css={(th: Theme) => css`
                                padding-right: ${th.spacing.sm};
                            `}
                        >
                            {expanded ? (
                                <Icon.ArrowCircleDown />
                            ) : (
                                <Icon.ArrowCircleUp />
                            )}
                        </span>
                    )}
                </AccordionItemState>
                {heading}
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel
            css={(th: Theme) => css`
                padding: ${th.spacing.sm} 0 ${th.spacing.sm} ${th.spacing.lg};
            `}
        >
            {children}
        </AccordionItemPanel>
    </AccordionItem>
)

const AccordionItemListItem = ({
    iconComp: IconComp,
    heading,
    text
}: {
    iconComp: IconType
    heading?: string
    text: string
}) => {
    return (
        <li
            css={(th: Theme) => css`
                color: ${th.colors.darkGray};
                font-size: ${th.fontSize.sm};
                margin-bottom: ${th.spacing.sm};
            `}
        >
            <span
                css={(th: Theme) => css`
                    color: ${th.colors.primary};
                    display: inline-block;
                    margin-right: ${th.spacing.sm};
                `}
            >
                <IconComp />
            </span>
            {heading && <strong>{heading}: </strong>}
            <span>{text}</span>
        </li>
    )
}

const CareerPositionAccordion = ({
    position,
    msgs
}: {
    position: CareerPositionInfo
    msgs: Msgs
}) => {
    const {
        jobStart,
        jobType,
        location,
        perks,
        tasks,
        salary,
        id,
        expectations
    } = position
    return (
        <Accordion
            css={(th: Theme) => css`
                margin: 0 0 ${th.spacing.sm};
            `}
        >
            <CareerPositionAccordionItem heading={t(msgs.career.basicInfo)}>
                <ul>
                    <AccordionItemListItem
                        iconComp={Icon.Check}
                        heading={t(msgs.career.location)}
                        text={location}
                    />
                    <AccordionItemListItem
                        iconComp={Icon.Check}
                        heading={t(msgs.career.jobStart)}
                        text={jobStart}
                    />
                    <AccordionItemListItem
                        iconComp={Icon.Check}
                        heading={t(msgs.career.jobType)}
                        text={jobType}
                    />
                    <AccordionItemListItem
                        iconComp={Icon.Check}
                        heading={t(msgs.career.salary)}
                        text={salary}
                    />
                </ul>
            </CareerPositionAccordionItem>
            <CareerPositionAccordionItem heading={t(msgs.career.requirements)}>
                <ul>
                    {expectations.map((e, i) => (
                        <AccordionItemListItem
                            iconComp={Icon.PlusCircle}
                            text={e}
                            key={`expectations-${id}-${i}`}
                        />
                    ))}
                </ul>
            </CareerPositionAccordionItem>
            {tasks.length > 0 && (
                <CareerPositionAccordionItem heading={t(msgs.career.tasks)}>
                    <ul>
                        {tasks.map((p, i) => (
                            <AccordionItemListItem
                                iconComp={Icon.ArrowCircleRight}
                                text={p}
                                key={`tasks-${id}-${i}`}
                            />
                        ))}
                    </ul>
                </CareerPositionAccordionItem>
            )}
            <CareerPositionAccordionItem heading={t(msgs.career.offerings)}>
                <ul>
                    {perks.map((p, i) => (
                        <AccordionItemListItem
                            iconComp={Icon.Check}
                            text={p}
                            key={`perks-${id}-${i}`}
                        />
                    ))}
                </ul>
            </CareerPositionAccordionItem>
        </Accordion>
    )
}

export default CareerPositionAccordion
